<script>
export default {
	components: {},
};
</script>

<template>
	<div class="mt-10 sm:mt-20">
		
		<div class="mt-10 sm:mt-20 bg-primary-light dark:bg-ternary-dark shadow-sm">
			<p class="mt-20 pt-10 font-general-semibold text-2xl sm:text-5xl font-semibold mb-2 text-ternary-dark dark:text-ternary-light">
				Služby
			</p>
		<!-- About me counters -->
		<div
			class="font-general-regular container mx-auto py-20 block sm:flex sm:justify-between sm:items-center"
		>
			<!-- Years of experience counter -->
			<div class="mb-20 sm:mb-0 serviceOffer">
				<div class="rounded-xl shadow-lg hover:shadow-xl mb-10 sm:mb-0 bg-secondary-light  p-5">
					<h1 class="font-general-semibold text-xl sm:text-5xl font-semibold mb-2 text-ternary-dark">Webové aplikácie</h1>
					<ul class="font-general-regular mt-10">
						<li>Správa existujúcich webových aplikácií.</li>
						<li>Tvorba jednoduchých prezentačných webových stránok v CMS WordPress.</li>
						<li>Väčšie webové aplikácie vo Vue.js alebo Angular.</li>
					</ul>
				</div>
			</div>

			<!-- Mobil Apky  -->
			<div class="mb-20 sm:mb-0 serviceOffer">
				<div class="rounded-xl shadow-lg hover:shadow-xl mb-10 sm:mb-0 bg-secondary-light p-5">
					<h1 class="font-general-semibold text-xl sm:text-5xl font-semibold mb-2 text-ternary-dark ">Mobilné aplikácie</h1>
					<ul class="font-general-regular mt-10">
						<li>Vytvorenie mobilnej aplikácie v Ionic.</li>
						<li>Vytvorenie základného dizajnu mobilnej aplikácie.</li>
						<li>Optimalizácia aplikácie pre operačný systém mobilu - iOS / Android.</li>
					</ul>
				</div>
			</div>

			<!-- Positive feedback counter
			<div class="mb-20 sm:mb-0">
				<div class="rounded-xl shadow-lg hover:shadow-xl cursor-pointer mb-10 sm:mb-0 bg-secondary-light dark:bg-ternary-dark singleProject p-5">
					<h1 class="font-general-semibold text-2xl sm:text-5xl font-semibold mb-2 text-ternary-dark dark:text-ternary-light">Dizajnérske práce</h1>
					<ul class="font-general-regular mt-10">
						<li>Úprave prezentačných fotografií podľa požiadaviek.</li>
						<li>Vytvorenie digitálnych obrázkov na marketingové účely prostredníctvom AI.</li>
						<li>Vytvorenie dizajnu webovej aplikácie.</li>
					</ul>
				</div>
			</div> -->

			<!-- Projects completed counter -->
			<div class="mb-20 sm:mb-0 serviceOffer">
				<div class="rounded-xl shadow-lg hover:shadow-xl mb-10 sm:mb-0 bg-secondary-light  p-5">
					<h1 class="font-general-semibold text-xl sm:text-5xl font-semibold mb-2 text-ternary-dark">Eshop</h1>
					<ul class="font-general-regular mt-10">
						<li>Správa existujúcich Eshopov.</li>
						<li>Tvorba nového Eshopu v CMS WordPress a Woocommerce.</li>
						<li>Napĺňanie obsahu webov.</li>
						<li>Optimalizácia rýchlosti načítavania existujúcich Eshopov.</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
	</div>
</template>

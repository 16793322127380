// Projects data.
// Note: Here we are getting data from a js file, but in a different project I'll be fetching these projects from some srt of APIs.

const projects = [
	{
		id: 8,
		title: 'MN Force',
		category: 'WordPress',
		type:'Prezentačná stránka',
		img: require('@/assets/images/mnforce/img1.png'),
	},
	{
		id: 9,
		title: 'G4 Group',
		category: 'WordPress',
		type:'Prezentačná stránka',
		img: require('@/assets/images/g4/img1.png'),
	},
	{
		id: 10,
		title: 'TK Apalucha',
		category: 'WordPress',
		type:'Prezentačná stránka',
		img: require('@/assets/images/apalucha/img1.png'),
	},
	{
		id: 0,
		title: 'Domka Lisková',
		category: 'WordPress',
		type:'Prezentačná stránka',
		img: require('@/assets/images/domka/Domka1.png'),
	},
	{
		id: 1,
		title: 'Nilio',
		category: 'WordPress',
		type:'Prezentačná stránka',
		img: require('@/assets/images/nilio/Nilio1.png'),
	},
	{
		id: 2,
		title: 'Ortoport',
		type:'Prezentačná stránka',
		category: 'WordPress',
		img: require('@/assets/images/ortoport/Ortoport1.png'),
	},
	{
		id: 3,
		title: 'BrickParadise',
		type:'Eshop',
		category: 'Woocommerce',
		img: require('@/assets/images/BrickParadise/BrickParadise2.png'),
	},
	{
		id: 4,
		title: 'Modlitby matiek',
		category: 'WordPress',
		type:'Prezentačná stránka',
		img: require('@/assets/images/modlitby/modlitby1.png'),
	},
	{
		id: 5,
		title: 'DIH Stuba',
		category: 'WordPress',
		type:'Prezentačná stránka',
		img: require('@/assets/images/dih/dih1.png'),
	},
	{
		id: 6,
		title: 'Domka združenie saleziánskej mládeže',
		category: 'Správca webu',
		type:'Prezentačná stránka',
		img: require('@/assets/images/domkaweb/domkaweb1.png'),
	},
	{
		id: 7,
		title: 'SPNZ',
		category: 'Správca webu',
		type:'Prezentačná stránka',
		img: require('@/assets/images/spnz/spnz1.png'),
	},
	// {
	// 	id: 8,
	// 	title: 'Portfolio',
	// 	category: 'Vue.js',
	// 	type:'Prezentačná stránka',
	// 	img: require('@/assets/images/ui-project-2.jpg'),
	// },
	// {
	// 	id: 12,
	// 	title: 'KBT Bratislava',
	// 	category: 'WordPress',
	// 	type:'Prezentačná stránka',
	// 	img: require('@/assets/images/domka/domka2.png'),
	// },
];

export default projects; 

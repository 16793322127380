<script>
import feather from 'feather-icons';

export default {
	name: 'Home',
	data: () => {
		return {
			theme: '',
		};
	},
	created() {
		this.theme = localStorage.getItem('theme') || 'light';
	},
	mounted() {
		feather.replace();
		this.theme = localStorage.getItem('theme') || 'light';
	},
	updated() {
		feather.replace();
	},
	methods: {},
};
</script>

<template>
	<section
		class="flex flex-col sm:justify-between items-center sm:flex-row mt-12 sm:mt-10"
	>
		<!-- Banner left contents -->
		<div class="w-full md:w-2/5 text-left">
			<h1
				class="font-general-semibold text-3xl md:text-3xl xl:text-4xl text-center sm:text-left text-ternary-dark dark:text-primary-light uppercase"
			>
				Ahojte, volám sa Tomáš
			</h1>
			<p
				class="font-general-medium mt-10 text-lg sm:text-xl  xl:text-2xl text-center sm:text-left leading-none text-gray-400"
			>
				Realizujem tvorbu webstránok, spravujem zabehnuté weby, programujem mobilné aplikácie a ochotne Vám pomôžem zrealizovať Váš digitálny nápad.
			</p>
			<div class="flex justify-center sm:block">
				<!-- <a
					download="Tomas-Pudis-CV.pdf"
					href="/files/tomaspudiscv.pdf"
					class="flex justify-center items-center w-36 sm:w-48 mt-12 mb-6 sm:mb-0 text-lg border border-indigo-200 dark:border-ternary-dark py-2.5 sm:py-3 shadow-lg rounded-lg bg-indigo-50 focus:ring-1 focus:ring-indigo-900 hover:bg-indigo-500 text-gray-500 hover:text-white duration-500"
					aria-label="Stiahnuť CV"
				>
					<i
						data-feather="arrow-down-circle"
						class="ml-0 sm:ml-1 mr-2 sm:mr-3 w-5 sm:w-6 duration-100"
					></i>
					<span
						class="text-sm sm:text-lg font-general-medium duration-100"
						>Stiahnuť CV</span
					></a
				> -->
			</div>
		</div>

		<!-- Banner right illustration -->
		<div class="w-full md:w-1/2 text-right float-right sm:text-center image-wrapper">
			<img
				v-if="theme === 'light'"
				src="@/assets/images/title.png"
				alt="Developer"
			/>
			<img
				v-else
				src="@/assets/images/titledark.png"
				alt="Developer"
			/>
		</div>
	</section>
</template>

<style scoped>
@media only screen and (max-width: 600px) {
	.image-wrapper img{
		margin: auto;
	}
}
</style>

<script>
export default {
	setup() {
		return {
			bios: [
				{
					id: 1,
					bio:
						'Ahoj! 👋 Som vášnivý web developer so zameraním na Wordpress, Angular a Node.js. Počas svojej kariéry som získal znalosti v oblasti budovania efektívnych a optimálnych riešení s využitím najvhodnejších nástrojov pre klienta a osvedčených postupov. Moja cesta vývojára bola naplnená viacerými výzvami, ktoré mi umožnili neustále sa vzdelávať a rásť v tomto stále sa meniacom digitálnom prostredí.',
				},
				{
					id: 2,
					bio:
						'Okrem svojich profesionálnych záujmov som otec rodiny, cestovateľ a športový nadšenec. Keď práve neprogramujem, pravdepodobne cestujem do nových destinácií alebo objavujem rôzne kultúry. Verím, že aj tieto mimopracovné záľuby pomáhajú pri riešení problémov, podporujú moju kreativitu a prispôsobivosť.',
				},
				{
					id: 2,
					bio:
						'Vždy sa rád porozprávam s rovnako zmýšľajúcimi ľuďmi, či už pri zdielaní vedomostí pri chladenom nápoji alebo prostredníctvom spolupráce na zmysluplných projektoch. Neváhajte ma osloviť v prípade čohokoľvek, čo súvisí s vývojom webu, prípadne ak sa chcete porozprávať o vašich obľúbených cestovateľských destináciách alebo športových aktivitách. 🌍⚽️',
				},
			],
		};
	},
};
</script>

<template>
	<div class="mt-20">
		<!-- Projects grid title -->
		<div class="text-center">
			<p
				class="font-general-semibold text-2xl sm:text-5xl font-semibold mb-2 text-ternary-dark dark:text-ternary-light"
			>
				O mne
			</p>
		</div>
		<div class="block sm:flex sm:gap-10 mt-10 sm:mt-20">
			<!-- About profile image -->
			<div class="w-full sm:w-1/4 mb-7 sm:mb-0 sm:text-center image-wrapper">
				<img
					src="@/assets/images/profile.jpg"
					class="rounded-xl w-96"
					alt="profilovka"
				/>
			</div>

			<!-- About details -->
			<div class="w-full sm:w-3/4 text-left">
				<p
					v-for="bio in bios"
					:key="bio.id"
					class="font-general-regular mb-4 text-ternary-dark dark:text-ternary-light text-lg"
				>
					{{ bio.bio }}
				</p>
			</div>
		</div>
	</div>
	
</template>
<style scoped>
@media only screen and (max-width: 600px) {
	.image-wrapper img{
		margin: auto;
	}
}
</style>

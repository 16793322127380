<script>
import AppBanner from "@/components/shared/AppBanner";
import ProjectsGrid from "../components/projects/ProjectsGrid.vue";
import AboutMe from "../components/about/AboutMe.vue";
import AboutCounter from "../components/about/AboutCounter.vue";
import AboutServices from "../components/about/AboutServices.vue";

export default {
  name: "Home",
  components: {
    AppBanner,
    ProjectsGrid,
    AboutMe,
    AboutCounter,
    AboutServices,
  },
};
</script>

<template>
  <div>
    <div class="container mx-auto">
      <!-- Banner -->
      <AppBanner class="mb-5 sm:mb-8" />
    </div>

    <!-- Services -->
    <AboutServices />

    <div class="container mx-auto">
      <AboutMe />
    </div>
    <AboutCounter />

    <div class="container mx-auto">
      <!-- Projects -->
      <ProjectsGrid />
    </div>
  </div>
</template>

<style scoped></style>

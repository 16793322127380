export const socialLinks = [
	{
		id: 1,
		name: 'Facebook',
		icon: 'facebook',
		url: 'https://www.facebook.com/tomas.pudis/',
	},
	{
		id: 3,
		name: 'Instagram',
		icon: 'instagram',
		url: 'https://www.instagram.com/tomas_pudis/',
	},
	{
		id: 4,
		name: 'LinkedIn',
		icon: 'linkedin',
		url: 'https://www.linkedin.com/in/tomas-pudis-920037185/',
	},
];
